import React from "react"

const Subfooter = () => {
  return (
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>Copyright &copy; {new Date().getFullYear()} All rights reserved | This site was made by
              <a className="sub-footer-creator" href="mailto:ota_2411@mail.ru?subject=application for the site"> Otabek Fayziev</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subfooter