import React from "react"
import { Link } from "gatsby"
import jQuery from "jquery"

const Header = () => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    jQuery(document).ready(function($) {
      $(window).scroll(function() {
        const scroll = $(window).scrollTop()
        const box = $(".header-text").height()
        const header = $("header").height()

        if (scroll >= box - header) {
          $("header").addClass("background-header")
        } else {
          $("header").removeClass("background-header")
        }
      })
    })
  }
  return (
    <header className="">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <h2>Eldkit</h2>
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                  aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/" activeClassName="active" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link activeClassName="active" className="nav-link" to="/about/">About Us</Link>
              </li>
              <li className="nav-item">
                <Link activeClassName="active" className="nav-link" to="/services/">Our Services</Link>
              </li>
              <li className="nav-item">
                <Link activeClassName="active" className="nav-link" to="/contact/">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header