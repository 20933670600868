import * as React from "react"
import { Link } from "gatsby"

const Subheader = () => (
    <div className="sub-header">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-xs-12">
            <ul className="left-info">
              <li><Link to="/contact/"><i className="fa fa-clock-o" />Mon-Sun 24/7</Link></li>
              <li><a href="tel:+1 224 269 4410"><i className="fa fa-phone" />+1 224 269 4410</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
)

export default Subheader
