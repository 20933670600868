import * as React from "react"
import Subheader from "./subheader"
import Header from "./header"
import Footer from "./footer"
import Subfooter from "./subfooter"
import jQuery from "jquery"

const Layout = ({ children }) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    jQuery(document).ready(function($) {
      $("#preloader").animate({
        "opacity": "0"
      }, 600, function() {
        setTimeout(function() {
          $("#preloader").css("visibility", "hidden").fadeOut()
        }, 300)
      })
    })
  }
  return (
    <>
      <div id="preloader">
        <div className="jumper">
          <div />
          <div />
          <div />
        </div>
      </div>
      <Subheader />
      <Header />
      {children}
      <Footer />
      <Subfooter />
    </>
  )
}

export default Layout
